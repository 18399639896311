import { gemiusImpress, type GemiusImpressData } from 'lib/gemius/gemius.ts';
import { useEffect, useRef } from 'preact/hooks';

type Props = {
	hitData: GemiusImpressData;
};

/**
 * Gemius analytics should be sent only on specific pages when they first load. This includes search page. However,
 * search page is an SPA, meaning searching does not keep reloading the page and any further Gemius hits get lost.
 * Since the first time this was implemented, Astro transitions were added into the mix.
 *
 * For simplicity, this component will send a Gemius hit only when it first renders. The additional Gemius hits that
 * should be triggered within search page are sent via `queryOffers` function call. That function is also called when
 * traversing the browser history, sparing us from defining any events for Astro transitions in this component and
 * correctly differentiating from pushing new history state, traversing back/forwards and checking if it's full-blown
 * page caused by transition or just SPA in-place navigation trigger.
 */
export function GemiusSnitch({ hitData }: Props) {
	// The component should never be re-mounted within a page, as it should always be inside the .astro page file.
	// Just in case the component is somehow re-rendered multiple times. We want to send the hit only once when a
	// page loads. Further hits for search page are handled by `queryOffers` function.
	const snitched = useRef(false);
	useEffect(() => {
		if (!snitched.current) {
			gemiusImpress(hitData);
			snitched.current = true;
		}
	}, []);

	return null;
}
